@import "vars";

html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
  font-family: $font-general;
}

/* Apply custom focus styles to all focused elements */
*:focus-visible {
  outline: black solid 0.25rem;
}

// Primary Layout Pages
.container-fluid {
  width: 100vw;
}

.container {
  display: block;
  margin: 0 auto;
  width: 90vw;
  max-width: 1600px;
}

.text-center {
  text-align: center;
}

.wlt-activity {
  width: 100%;
  height: 100%;
  border: 0.0625rem transparent;
  padding: 1rem;
  position: relative;

  .game-area {
    height: 80%;
  }

  .progress-bar-area {
    height: 10%;
    position: relative;
    top: 3rem;
  }
}

.carousel-play-button{
  position: absolute;
  top: 56%;
  left: 48%;
}

#play-button-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  #play-button {
    position: relative;
    transform-origin: center;

    &:focus,
    &:hover {
      background-color: $black;
      box-shadow: 0 0 0 0.1rem $black;
    }
  }

  #play-cursor {
    position: absolute;
    top: 55%;
    left: 40%;
  }
}

/* -------------------------------------- elements ------------------------------------

 --------------------------------------------------------------------------------------------------*/

// button used for login flow
.login-button {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;

  .p-button,
  .btn {
    background-color: $color-wilson;
    color: white;
    border: none;
    border-radius: 0.2rem;
    min-width: 7.5rem;
    padding: 0.5rem 1rem;
    font-family: $font-fun;
    letter-spacing: -0.05rem;
    font-size: $font-size-small;
    cursor: pointer;
    box-shadow: 0 0.1rem 0.25rem 0 rgba(0, 0, 0, 0.28);
    transition: all 0.25s ease;

    &:hover {
      transform: translateY(-0.25rem);
      opacity: 0.9;
    }
  }
}

// global helper styles
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled {
  cursor: default;
  pointer-events: none;
}

.dropshadow {
  -webkit-box-shadow: 10px 10px 3px -7px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 10px 10px 3px -7px rgba(0, 0, 0, 0.26);
  box-shadow: 10px 10px 3px -7px rgba(0, 0, 0, 0.26);
}

.dropshadow-blur {
  -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.75);
}

.dropshadow-filter {
  -webkit-filter: drop-shadow(1px 1px 5px rgb(100, 100, 100));
  filter: drop-shadow(1px 1px 5px rgb(100, 100, 100));
}

.absolute-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

// ensure orientation warning is at highest z-index
.p-dialog-mask {
  z-index: 100000000 !important;
}

/* --------------------------------------Animations, Transitions ------------------------------------

--------------------------------------------------------------------------------------------------*/

.pulse-animation {
  animation: pulse 1s infinite;
}

.spin {
  animation: spin 0.5s infinite linear;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border: 0 !important;
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

// MARKINGS

.overlayDiv {
  position: absolute;
  text-align: center;
  top: 0;

  &.hide {
    opacity: 0;
    width: 0;
  }
}

.contentDiv {
  width: 100%;
  height: 2rem;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  background: center / contain no-repeat;
  background-size: 1rem 1rem;
  background-position-y: top;
}

.top {
  top: -45%;
  background-position-y: 10px;
}

.below {
  bottom: -120%;
  background-position-y: 10px;
  font-size: medium;
}

.middle {
  bottom: 20%;
  font-weight: bold;
  // TODOWILSON font-size: 42px;
}

.underline,
.dashed {
  bottom: -50%;
  border-bottom: 0.0625rem solid black;
}

.dashed {
  border: 0;
  border-bottom: dashed 0.2rem;
  border-style: dashed; // TODOWILSON fix
}

.circle,
.rectangle {
  border: 2px solid black;
  top: 0;
  left: -10%;
  height: 110%;
  width: 120%;
}

.circle {
  border-radius: 50%;
}

.scooped {
  position: relative;
  bottom: -20%;
}

.scooped:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: solid 2px black;
  border-color: transparent transparent black transparent;
  border-radius: 50%;
}

.dialog-mask {
  position: absolute;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.25);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  padding: 2rem 7.5rem;
  background-color: $white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  border-radius: $border-radius;
  font-family: $font-fun;
  letter-spacing: -0.05rem;
}

p-dialog,
.dialog {
  .header-icon {
    width: 3rem;
    height: 3rem;
  }

  .cta-sound-combo {
    position: relative;
  }

  .t2s-btn {
    &.bottom-right {
      position: absolute;
      right: -6.5rem;
      bottom: -1rem;
    }

    &.bottom-right-sm {
      position: absolute;
      right: 0;
      bottom: -1rem;
    }

    img {
      width: 2.5rem;
    }
  }
}

.t2s-btn {
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

.btn-primary {
  background-color: $color-wilson;
  color: white;
  border: none;
  border-radius: 0.2rem;
  min-width: 7.5rem;
  padding: 0.5rem 1rem;
  font-family: $font-fun;
  letter-spacing: -0.05rem;
  font-size: $font-size-small;
  cursor: pointer;
  box-shadow: 0 0.1rem 0.25rem 0 rgba(0, 0, 0, 0.28);
  transition: all 0.25s ease;

  &:hover {
    transform: translateY(-0.25rem);
    opacity: 0.9;
  }
}

.image-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  &.round {
    border-radius: 50%;
  }

  i,
  img {
    margin: 0;
    padding: 0;
  }
}

/* Hide the up and down arrows for Webkit browsers */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the up and down arrows for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}