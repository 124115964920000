// breakpoints
$bp-small: 48rem; // 768
$bp-medium: 62rem; // 992
$bp-large: 75rem; // 1200

$bp-min-width: 64rem; // 1024px supported and above
$bp-min-height: 48rem; // 768 supported and above

$bp-min-height-small: 52rem; // 832px, iPad Air etc..
$bp-min-height-medium: 63.9rem; // below 1024px  iPad Pro etc.

// z-index of items that should be in front of bursts
$burst-target-z-index: 10;

// Colors
// ...brand
$color-wilson: #003da5;
$color-wilson-secondary: #71ddb7;
$color-fun-blue: #c6ebfb;
$color-pre-k: #8ed8f8;
$color-level-k: #009cde;
$color-level-1: #e8927c;
$color-level-2: #5cb8b2;
$color-level-3: #a273ad;
// ...generic colors
$white: #ffffff;
$almost-white: #f2f2f2;
$gray-light: #d9d9d9;
$gray-medium: #757575;
$gray-dark: #707070;
$black: #000000;
$red: #cd2026;
$yellow: #f9e344;
$green: #177d4d;
// ...transparency
$black50: rgba(0, 0, 0, 0.5);
// ...element colors
$color-success: $green;
$color-error: $red;
$color-shade-light: $white;
$color-shade-dark: $black;
$color-outline-light: $gray-light;
$color-outline-medium: $gray-medium;
$color-outline-dark: $gray-dark;
$color-cards-1: #fffef8;
$color-cards-2: #fae79a;
$color-cards-3: #edc7bc;
$color-cards-4: #c1d8ac;
$color-cards-5: #d9d9d9;
$color-loading-1: $color-level-1;
$color-loading-2: $color-level-2;
$color-loading-3: $color-level-3;
$color-inactive-1: #d5eceb;
$color-button-1: #2f638d;
$color-suffix-panel: $yellow;
$color-avatar-1: $color-wilson;
$color-avatar-2: $color-level-1;
$color-link-text: #005bff;
$color-bank: #444c85;
$color-bank-level-2: #1D7A87;
$color-bank-level-3: #474799;
$color-bank-outline: #333862;
$color-bank-outline-level-2: #045D6B;
$color-bank-outline-level-3: #353571;
$color-panel-active: #a6a6a6;
$color-panel-choices: #c6c6f0;
$color-how-to-play: #1d1c1d;
$color-video-l1: #003da5;
$color-video-l2: #4d1e9f;
$color-video-l3: #0e817b;
$color-panel-light: #c6c6f0;
$color-border-bottom: #353571;

$color-go-button: $color-wilson-secondary;
// ...score bar at end of activities
$background-score-bar: linear-gradient(180deg, rgba(0, 50, 148, 1) 50%, rgba(64, 109, 187, 1) 100%);
$background-score-innerbar-1: linear-gradient(180deg, rgba(232, 146, 124, 1) 50%, rgba(195, 123, 104, 1) 50%);
$background-score-innerbar-2: linear-gradient(180deg, rgba(92, 184, 178, 1) 50%, rgba(77, 155, 150, 1) 50%);
$background-score-innerbar-3: linear-gradient(180deg, rgba(185, 150, 194, 1) 50%, rgba(155, 126, 163, 1) 50%);
// ...score ranges
$color-score-high: $green;
$color-score-medium: $yellow;
$color-score-low: $red;

// Font Sizes
$font-size-x-small: 0.5rem; // 8px
$font-size-tooltip: 0.625rem; // 10px
$font-size-small: 1rem; // 16px
$font-size-medium: 1.15rem; // 18px
$font-size-title: 1.375rem; // 22px
$font-size-body: 1.5rem; // 24px
$font-size-heading: 2.125rem; // 34px
$font-size-card-small: 2rem; // 32px   (original) 2.5rem; // 40px
$font-size-card-medium: 2.875rem; // 46px
$font-size-sentence: 3rem; // 48px was 3.375rem; // 54px
$font-size-card-large: 3.75rem; // 60px
$font-size-card-extra-large: 4.5rem; // 72px
$font-size-carousel: 8rem; // 128px

// Font Colors
$font-color-primary: #000000;
$font-color-secondary: #a7348d;

// Font Families
$font-primary: "Wilson Manuscript", Helvetica, sans-serif;
$font-secondary: "Wilson Bookman", Helvetica, sans-serif;
$font-general: "Open Sans", Helvetica, sans-serif;
$font-general-bold: "Open Sans Bold", Helvetica, sans-serif;
$font-general-semi-bold: "Open Sans SemiBold", Helvetica, sans-serif;
$font-general-extra-bold: "Open Sans ExtraBold", Helvetica, sans-serif;
$font-general-medium: "Open Sans Medium", Helvetica, sans-serif;
$font-fun: "Lexend", Helvetica, sans-serif !important;

// Other
$border-radius: 0.313rem; // 5px
$border-radius-larger: 0.5rem; // 8px
$border-card-radius: 0.625rem; // 10px
$border-card-width: 0.0625rem; // 1px
$border-width: 0.5rem; // 8px
$border-width-smaller: 0.375rem; // 6px
$header-height: 4rem;

// Activity Container Dimensions
$container-width: 82rem; // 1312 px
$container-height: 55rem; // 880px
