// OPEN SANS
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  src: url("/assets/fonts/Open Sans/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans Medium";
  font-style: normal;
  src: url("/assets/fonts/Open Sans/OpenSans-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans Bold";
  font-style: normal;
  src: url("/assets/fonts/Open Sans/OpenSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans SemiBold";
  font-style: normal;
  src: url("/assets/fonts/Open Sans/OpenSans-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans ExtraBold";
  font-style: normal;
  src: url("/assets/fonts/Open Sans/OpenSans-ExtraBold.ttf") format("truetype");
}

// WILSON
@font-face {
  font-family: "Wilson Bookman";
  font-style: normal;
  src: url("/assets/fonts/Wilson Bookman/WBitclbt.ttf") format("truetype");
}
@font-face {
  font-family: "Wilson Manuscript";
  font-style: normal;
  src: url("/assets/fonts/Wilson Manuscript/Wilson_Mn.ttf") format("truetype");
}

// LEXEND
@font-face {
  font-family: "Lexend";
  font-weight: 100;
  font-style: normal;
  src: url("/assets/fonts/Lexend/Lexend-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Lexend";
  font-weight: 200;
  font-style: normal;
  src: url("/assets/fonts/Lexend/Lexend-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Lexend";
  font-weight: 300;
  font-style: normal;
  src: url("/assets/fonts/Lexend/Lexend-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lexend";
  font-weight: 400;
  font-style: normal;
  src: url("/assets/fonts/Lexend/Lexend-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lexend";
  font-weight: 500;
  font-style: normal;
  src: url("/assets/fonts/Lexend/Lexend-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Lexend";
  font-weight: 600;
  font-style: normal;
  src: url("/assets/fonts/Lexend/Lexend-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Lexend";
  font-weight: 700;
  font-style: normal;
  src: url("/assets/fonts/Lexend/Lexend-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lexend";
  font-weight: 800;
  font-style: normal;
  src: url("/assets/fonts/Lexend/Lexend-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Lexend";
  font-weight: 900;
  font-style: normal;
  src: url("/assets/fonts/Lexend/Lexend-Black.ttf") format("truetype");
}
